* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #ff6600;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec21;
  --darkGrey: #ebebeb;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --secondaryColor: rgba(0, 0, 0, 0.867);
}

@font-face {
  font-family: "Hamlin";
  src: url("./fonts/Hamlin_Extra_Bold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Hamlin";
  src: url("./fonts/Hamlin_Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Hamlin";
  src: url("./fonts/Hamlin.ttf");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hamlin";
  src: url("./fonts/Hamlin_Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;400&display=swap");

.btn-secondary {
  padding: 10px;
  font-size: 25px;
  background: #ff6600;
  color: white;
  border: solid 2px #ffffff;
  border-radius: 20px;
  margin: 5px;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.ri-trigger {
  display: inline-block;
  width: 100% !important;
  height: 300px !important;
  background-size: cover;
  background-position: 50% 50%;
}
.loading img {
  width: 30px;
}
.container {
  width: 95vw;
  max-width: 1170px;
  margin: 0 auto;
}
/* globals */
body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: "Hamlin";
  line-height: 1.2;
  font-weight: 300;
  font-size: 16px;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
  font-family: "Hamlin";
  font-weight: 800;
}
h2 {
  font-size: 2em;
  margin-bottom: 10px;
  font-family: "Hamlin";
  font-weight: 800;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 0;
  font-family: "Hamlin";
  font-weight: 800;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
  font-weight: 800;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
p {
  letter-spacing: -0.4px;
}
.btn-primary {
  margin: 5px;
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.btn-secondary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
.nav-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  z-index: 1;
  height: 76px;
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  z-index: 1;
}
.logo {
  max-width: 63px;
}
.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}
@media (max-width: 760px) {
  .feature__list--content {
    font-size: 13px;
    text-align: left;
  }
  .feature__list--icon {
    border: none !important;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-content: space-between;
  }
  .containerx {
    max-width: 100% !important;
  }
  .services {
    padding: 0 !important;
  }
  .services h6 {
    letter-spacing: var(--mainSpacing);
    text-align: left;
    width: 95% !important;
    margin: 0 auto;
  }
  .lte-header {
    font-size: 30px !important;
    text-align: left;
    margin: 0 auto;
    width: 90% !important;
    font-weight: 400;
  }
  .fa-phone-volume {
    font-weight: 900;
    font-size: 20px !important;
    background: #ff6600;
    color: white;
    height: 40px !important;
    border-radius: 50px;
    width: 40px !important;
    line-height: 2;
  }
  .call-icon span {
    color: black;
    font-size: 20px !important;
    margin: 10px;
  }
  .section-title {
    text-align: center;
    margin-bottom: 0rem !important;
  }
  .nav-links a {
    display: block;
    text-decoration: none;
    padding: 1rem 0;
    color: var(--mainBlack);
    transition: var(--mainTransition);
    text-align: left;
    font-size: 1rem;
    border-bottom: solid 1px #99999933;
    letter-spacing: var(--mainSpacing);
    font-weight: 600;
  }
  .section-title h4 {
    font-size: 1rem !important;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .col-5,
  .col-7 {
    width: 50%;
  }
  .col-6 {
    width: 50%;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 10rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 66px);
  background: url("./images/_25-Hero-D.jfif") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultHero,
.roomsHerol {
  min-height: calc(100vh - 66px);
  background: url("./images/_25-Hero-D.jfif") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultHero,
.roomsHerot {
  min-height: calc(100vh - 66px);
  background: url("./images/_25-Hero-D.jfif") bottom/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomsHero {
  background-image: url("./images//innerbanner.jpg");
  min-height: 60vh;
}
.roomsHerol {
  background-image: url("./images/lithium.jpg");
  min-height: 60vh;
}
.roomsHerot {
  background-image: url("./images/tubular.jpg");
  min-height: 60vh;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
img {
  width: 100%;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
@media (max-width: 760px) {
  .row {
    display: block !important;
  }
  .col-3,
  .col-9,
  .col-4,
  .col-8 .col-9 img {
    width: 100% !important;
  }
}
.row {
  display: flex;
}
.col-4 {
  width: 60%;
  padding: 10px;
}
.col-3 {
  width: 30%;
  padding: 0;
}
.col-9 {
  padding: 0;
  width: 70%;
}
.services {
  /* padding: 2rem 0; */
}
.services p {
  padding: 10px;
  margin-bottom: 15px;
}
video {
  width: 100%;
}
.services {
  background: var(--darkGrey);
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: var(--mainSpacing);
}
.services p {
  text-align: justify !important;
  width: 95%;
  margin: 0 auto;
  line-height: 2;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
  .center-x {
    width: 75%;
    margin: 10px auto;
    padding: 10px;
    font-size: 18px;
    line-height: 1.9;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 80%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
@media screen and (min-width: 776px) {
  .gallery .featured-rooms-center {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    width: 95vw;
  }
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured rooms */
/* room */
.room {
  border: 1px solid #3333333b;
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
}
.room:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
/* .img-container:hover {
  background: rgba(0, 0, 0, 0.8);
} */
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}
.room-info {
  background: var(--darkGrey);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
@media (min-width: 760px) {
  .action {
    display: flex;
    text-align: center;
  }
}

.desc h3 {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  /* .info {
    padding-left: 3rem;
  } */
}
/* end of single room*/
/* roomlist */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}

.footer {
  background: grey;
  color: black;
  text-align: center;
}
.footer-links {
  display: flex;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-around;
  padding: 10px;
}
.footer-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: WHITE;
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.footer-links a:hover {
  color: var(--primaryColor);
}
/* end of rooms fitler*/

.containerx {
  margin: 0 auto;
  max-width: 45%;
}
.wwu {
  width: 70%;
}
.fx {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}
.title {
  text-align: center;
  margin: 35px 0;
  color: #fe9859;
  text-transform: uppercase;
}

.fx-img {
  height: 100%;
  width: 100%;
}

@media (min-width: 760px) {
  .col-lg-4 {
    width: 40%;
  }
  .col-lg-6 {
    width: 50%;
  }
}

#service .box {
  cursor: pointer;
}

#service .box:hover {
  cursor: pointer;
  background: blue;
}

.lte-subheader {
  color: #f35d22;
}

.lte-subheader:before {
  content: "";
  border-bottom: 1px solid;
  border-color: #f35d22;
  width: 50px;
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 15px;
  margin-inline-start: 0px;
  margin-inline-end: 15px;
}

.lte-header {
  font-size: 33px;
  text-align: left;
  margin: 0 auto;
  width: 80%;
  font-weight: 400;
}
.lte-header span {
  color: #fe9859;
}

.services h6 {
  letter-spacing: var(--mainSpacing);
  text-align: left;
  width: 80% !important;
  margin: 0 auto;
}
.services {
  background: white;
}

.fa-phone-volume {
  font-weight: 900;
  font-size: 30px;
  background: #ff6600;
  color: white;
  height: 60px;
  border-radius: 50px;
  width: 60px;
  line-height: 2;
}
.df {
  display: flex;
  justify-content: space-around;
}
.call-icon span {
  color: black;
  font-size: 20px;
  margin: 10px;
}
.benefits {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  padding: 10px;
}
.benefits ul {
  display: flex;
  font-size: 12px;
  color: #ff6600;
  font-weight: 600;
}
.benefits ul li {
  padding: auto;
}
em {
  font-weight: bold;
  text-transform: uppercase;
  font-style: normal;
}

.col-lg-3 {
  width: 33.3%;
}

@media (max-width: 768px) {
  .col-md-6 {
    width: 100% !important;
  }
}

.slider-container {
  position: relative;
}
.text-container {
  position: absolute;
  z-index: 9999;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}

.right-sec,
.left-sec {
  color: white;
  padding: 30px;
  margin: 30px;
  max-width: 600px;
}

.right-sec h2,
.left-sec h2 {
  font-size: 67px;
  text-transform: capitalize;
}

.carousel-content {
  padding: 10px;
  width: 550px;
}
.carousel-content button {
  padding: 10px;
  font-size: 25px;
  background: #ff6600;
  color: white;
  border: solid 2px #ffffff;
  border-radius: 20px;
}
.slick-initialized .slick-slide {
  display: block;
  position: relative;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 11%); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.services ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--primaryColor); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-size: 20px;
}

.services ul li::marker {
  color: hotpink;
  font-size: 5rem;
}

@media (min-width: 800px) {
  .no-des {
    display: none;
  }
}
@media (max-width: 768px) {
  .col-xs-12 {
    width: 100%;
  }
  .btn-secondary {
    font-size: 18px !important;
  }
  .p-5 {
    margin-bottom: 0px !important;
  }
  .no-mb {
    display: none;
  }
  .right-sec,
  .left-sec {
    color: white;
    padding: 5px;
    margin: 5px;
    max-width: 500px;
  }
  .carousel-content {
    padding: 5px;
    width: 100%;
    background: #0000007a;
  }
  .right-sec h2,
  .left-sec h2 {
    font-size: 35px;
    text-transform: capitalize;
  }
  .services ul li {
    text-align: left;
    width: 400px;
    margin: 0px auto;
    width: 80%;
  }
}

.packages-header {
  background: #ff6600;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
}
.p-5 {
  padding: 10px;
  border: solid #ff6600;
  margin-bottom: 10px;
}

.p-5 li {
  margin-bottom: 10px;
}
.p-5 h3 {
  text-align: center;
  margin-bottom: 25px;
}
a {
  color: #ff6600;
}
p {
  margin-bottom: 10px;
}

.flex {
  display: flex;
}
.benefit-wrapper {
  max-width: 550px;
  margin: 0 auto;
  padding: 10px;
}

.feature__list--icon {
  border: solid;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: space-between;
}
.feature__list {
  align-items: baseline;
}
.feature__list--content {
  margin-left: 10px;
}
.divider {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1rem auto;
}
.card {
  flex: 1;
}
.package-container {
  max-width: 900px;
  margin: 0 auto !important;
}
.card-body {
  padding: 10px;
  background: white;
  margin: 10px;
}
.service__content--h5 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.service__icon--h5 {
  font-size: 40px;
  flex: 1;
}
.fw-600 {
  padding: 10px;
}
.service__text--h5 {
  flex: 3;
}
.card-body:hover {
  color: white;
  background: #bfbfbf;
}

.my-float2 {
  font-size: 13px;
  position: absolute;
  left: -94px;
  top: 28px;
  background: #0b913e;
  padding: 4px;
  border-radius: 29px;
  font-weight: bolder;
}

.policy h3 {
  padding: 10px 0;
}

/* benefit sections */
.overview-content h6 {
  font-size: 16px;
  font-weight: 700;
  color: #ff9800;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.overview-content h2 {
  font-size: 38px;
  color: #0b0b31;
  margin: 8px 0 15px 0;
}
.overview-content p {
  margin: 0 0 0 0;
}
.overview-content .features-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  z-index: 0;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  position: relative;
  margin-bottom: 0;
  margin-top: 16px;
  margin-left: -10px;
  margin-right: -10px;
}
.overview-content .features-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.overview-content .features-list li span {
  display: block;
  background-color: #ff6600;
  border-radius: 5px;
  padding: 15px 15px;
  z-index: 1;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #fff;
  border-left: 2px solid #000;
  font-weight: 600;
  font-size: 18px;
  -webkit-box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
  box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
}
.col-5 .overview-content .features-list li span {
  display: block;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 15px;
  z-index: 1;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #000;
  border-left: 2px solid #ff6600;
  font-weight: 600;
  font-size: 18px;
  -webkit-box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
  box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
}
.overview-content .features-list li span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  border-radius: 3px;
  background-color: #ff6600;
  z-index: -1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.overview-content .features-list li span:hover {
  color: #000;
  background-color: #ff6600;
}
.overview-content .features-list li span:hover::before {
  width: 100%;
  background-color: #7b68ee;
}
.overview-content .features-list li span:hover:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  border-radius: 3px;

  z-index: -1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.overview-content {
  padding: 20px;
}

section {
  margin: 40px 0 !important;
}

.btn {
  cursor: pointer;
}
.card.col-1 {
  align-items: center;
  background: #ff6600;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 10px;
  min-height: 168px;
  text-align: center;
  color: white !important;
  text-transform: uppercase;
}
.features-list li {
  position: relative;
}

.features-list li img {
  width: 20px;
  position: absolute;
  right: 18px;
  top: 14px;
  z-index: 2;
  cursor: no-drop;
}
